#cookiebanner .container {
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    z-index: 9999;
    background: #fff;
    width: 100%;
    margin: 0;
    left: 0;
    padding: 10px;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 9px 0 rgba(0,0,0,.7);
}

#cookiebanner .content,
#cookiebanner .consent-preferences {
    flex-basis: 100%;
}

#cookiebanner.guideline .background {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    background-color: #00000080;
}

#cookiebanner.guideline .container {
    position: absolute;
    bottom: inherit;
}

#cookiebanner .button {
    margin-left: 1em;
    margin-top: 1em;
}

#cookiebanner p {
    margin-bottom: 0;
    font-size: 0.9rem;
}

#cookiebanner .consent {
    width: 100%;
    margin-top: 1rem;
}

#cookiebanner .consent-intro {
    margin-bottom: 1rem;
}

#cookiebanner .consent {
    display: none;
}

body#checkout #cookiebanner .container {
    min-height: inherit;
}

@media (min-width: 992px) {
    #cookiebanner.guideline .container {
        width: 50%;
        margin: 30px auto;
        padding: 60px;
    }
}

/************************************************************************************************/
.GcCookie * {
    box-sizing: border-box
}

.GcCookie .cookie-heading {
    font-weight: bold;
    font-size: 1.2rem;
}

.GcCookie .cookie-logo {
    width: 100%;
}

.GcCookie .cookie-heading .cookie-logo {
    max-width: 20px;
}

.GcCookie *:before,
.GcCookie *:after {
    box-sizing: inherit
}

#cookie-box-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .6);
    transition: background-color .25s linear;
}

#GcCookieBox .flex-100 {
    flex: 100;
}

#GcCookieBox .flex-85 {
    flex: 85;
}

#GcCookieBox .flex-15 {
    flex: 15;
}

#GcCookieBox .flex-10 {
    flex: 10;
}

#GcCookieBox .wrapper {
    display: block;
    max-width: 385px;
    width: 100%;
    z-index: 10000000;
    background: #FFF;
    padding: 2rem 0 2rem 2rem;
    border-radius: 8px;
}

#GcCookieBox .wrapper.advanced,
#GcCookieBox .wrapper.compact-view {
    max-width: 550px;
}

#GcCookieBox .cookie-header {
    display: flex;
    margin-bottom: 0.8rem;
    margin-right: 2rem;
    align-items: center;
}
#GcCookieBox .cookie-box-simple {
    .cookie-settings,
    .cookie-buttons {
        margin-right: 2rem;
    }
}

#GcCookieBox .cookie-consent-body {
    margin-right: 2rem;//check
}
#GcCookieBox .cookie-logo-container {
    display: none;
}

#GcCookieBox .cookie-box-advanced .cookie-header {
    align-items: stretch;
}

#GcCookieBox .cookie-box-advanced .cookie-heading {
    margin-bottom: 0.6rem;
}

#GcCookieBox .accept-button-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 1rem;
}

#GcCookieBox .compact-view .accept-button-row {
    margin: 1rem 2rem 0px 0px;//check
        flex-wrap: nowrap;
    justify-content: space-between;
}

#GcCookieBox .accept-button-row > *{
    margin-right: 1rem;
}

#GcCookieBox .compact-view .accept-button-row > * {
    margin: 0px;
    flex-basis: 45%;
}

#GcCookieBox .compact-view .accept-button-row > .accept-button:nth-child(2) {
    order: 2;
}
#GcCookieBox .compact-view .accept-button-row > .accept-button:nth-child(3) {
    display: none;
        margin: 0px 15px;
}

#GcCookieBox .accept-button .btn {
    display: inline-block;
    width: 100%;
    border: 1px solid;
}

#GcCookieBox .back-link {
    flex: 2;
    text-align: right;
    font-size: 0.8rem;
}

#GcCookieBox .compact-view .back-link {
    display: none;
}

#GcCookieBox .cookie-box-simple,
#GcCookieBox .cookie-box-advanced {
    overflow: auto !important;
    width: auto !important;
    position: relative;
    max-height: 85vh;
}

/*#GcCookieBox .compact-view .cookie-box-advanced {
    overflow: visible !important;
}*/

#GcCookieBox .wrapper.compact-view .cookie-box-simple {
    display: none !important;
}

#GcCookieBox .wrapper.compact-view .cookie-box-advanced {
    display: block !important;
}

.consent-switch {
    text-align: right;
}

.GcCookie input:checked~.checkerbox:after {
    display: block
}

.GcCookie input:disabled~.checkerbox:after {
    display: block
}

.GcCookie .checkerbox {
    border-style: solid;
    border-width: 2px;
    padding: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    width: 20px;
    transition: .25s
}

.GcCookie .checkerbox:after {
    content: "";
    display: none;
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    position: absolute;
    left: 5px;
    top: 1px;
    height: 11px;
    width: 6px;
    transform: rotate(45deg)
}

.GcCookie span.close{
    position: absolute;
    right: 1rem;
    top: 0;
    font-size: 2rem;
    line-height: 1rem;
}

#GcCookieBox ul {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    list-style: none;
    margin: .6rem 0 .8rem;
    padding: 0
}

#GcCookieBox ul li {
    margin: 0 .8rem 0 0;
}

.GcCookie input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    right: 0;
}

.GcCookie .checkerbox-label {
    cursor: pointer;
    display: block;
    padding-left: 2em;
    position: relative
}

.GcCookie .checkerbox-label input {
    position: absolute;
    opacity: 0;
    z-index: -1
}

.GcCookie .checkerbox-label input:checked~.checkerbox:after {
    display: block
}

.GcCookie .checkerbox-label input:disabled~.checkerbox:after {
    display: block
}

.GcCookie .checkerbox-label input:disabled~.checkerbox {
    background-color: #e8e8e8;
    border-color: #e8e8e8
}

.GcCookie .checkerbox-label .checkerbox {
    border-style: solid;
    border-width: 2px;
    padding: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    width: 20px;
    transition: .25s
}

.GcCookie .checkerbox-label .checkerbox:after {
    content: "";
    display: none;
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    position: absolute;
    left: 5px;
    top: 1px;
    height: 11px;
    width: 6px;
    transform: rotate(45deg)
}


#GcCookieBox .settings {
    margin: 0 0 10px;
}

#GcCookieBox .legal {
    font-size: .7rem;
    text-align: center;
    margin: 0;
}

#GcCookieBox .compact-view .legal {
    text-align: left;
}

#GcCookieBox .legal a {
    color: #7a7a7a;
}

#GcCookieBox .settings {
    font-size: .7rem;
    text-align: center;
}

#GcCookieBox .separator:before {
    content: "|";
    display: inline-block;
    margin: 0 2px
}

#GcCookieBox .consent-item {
    background: #efefef;
    margin: 0 0 1rem 0;
}

#GcCookieBox .compact-view .with-category-separator .consent-item {
    margin-bottom: 0px;
}

#GcCookieBox .consent-category-separator {
    display: none;
    margin: 0.1rem 0px 0.1rem 0px;
    height: 0px;
    width: 100%;
    border-top: 1px solid #d3d3d3;
}

#GcCookieBox .compact-view .with-category-separator .consent-category-separator {
    display: block;
}

#GcCookieBox .compact-view .consent-item.closed {
}

#GcCookieBox .compact-view .consent-item.closed .consents-box,
#GcCookieBox .compact-view .consent-item.closed .consent-content {
    display: none;
}

#GcCookieBox .cookie-box-advanced table {
    margin: 0 0 1rem 0;
    width: 100%;
    background-color: #fff;
}

#GcCookieBox .cookie-box-advanced table td,
#GcCookieBox .cookie-box-advanced table th {
    padding: 0.25rem 0.5rem;
    border-bottom: 1px solid;
    background-color: #fff;
    border-color: #efefef;
    font-size: 0.7rem;
}

#GcCookieBox .cookie-box-advanced table tr:last-child td,
#GcCookieBox .cookie-box-advanced table tr:last-child th {
    border-bottom: 0;
}

#GcCookieBox .cookie-box-advanced table th {
    text-align: left;
    width: 40%;
}

#GcCookieBox .consent-header {
    display: flex;
}

#GcCookieBox .compact-view .consent-item .consent-header {

}

#GcCookieBox .consent-heading {
    flex: 2;
    font-weight: 700;
}

#GcCookieBox .compact-view .consent-header  svg {
    display: none;
}

#GcCookieBox .compact-view .consent-item .consent-header .consent-heading {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

#GcCookieBox .compact-view .consent-item .consent-header svg {
    display: block;
    cursor: inherit;
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
    fill: currentcolor;
    cursor: pointer;
    margin-right: 15px;
}

#GcCookieBox .compact-view .consent-item.closed .consent-header svg {
    transform: rotate(90deg);
}

#GcCookieBox label.consent-btn-switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
    vertical-align: middle;
    margin: 0 0 0 0.5rem;
}

#GcCookieBox .consent-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;
    border-radius: 15px;
    background: #bbb;
}

#GcCookieBox label.consent-btn-switch input:checked+.consent-slider {
    background: #2fb5d2;
}

#GcCookieBox label.consent-btn-switch input:checked+.consent-slider:before {
    transform: translateX(28px);
}

#GcCookieBox .consent-btn-switch .consent-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    transition: .5s;
    border-radius: 10px;
}

#GcCookieBox label.consent-btn-switch.small {
    width: 35px;
    height: 20px;
    margin: 0 0 0 0.5rem;
}

#GcCookieBox .small .consent-slider {
    border-radius: 10px;
}

#GcCookieBox label.consent-btn-switch.small input:checked+.consent-slider:before {
    transform: translateX(14px);
}

#GcCookieBox .consent-btn-switch.small .consent-slider:before {
    height: 12px;
    width: 12px;
    border-radius: 6px;
}

#GcCookieBox .consent-btn-switch-status {
    display: inline-block;
    margin: 0 .25rem;
    vertical-align: middle
}

#GcCookieBox .hidden {
    display: none;
}

#GcCookieBox .cookie-info-toggle {
    text-align: center;
}

.cookie-info-toggle {
    text-align: center;
}

.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

#GcCookieBox .consent-element {
    margin-bottom: 0.5rem;
}

#GcCookieBox .consent-element-header {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 2fr;
    justify-content: space-between;
    background-color: white;
    // padding: 0.5rem; //check
}
#GcCookieBox .list-view .consent-element-header {
    display: block;
}

#GcCookieBox .consent-element-header .consent-element-accept {
    justify-self: right;
}

#GcCookieBox .consent-elements-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.7rem;
    line-height: 1rem;
}

#GcCookieBox ul.consent-elements-box > li {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

#GcCookieBox .list-view ul.consent-elements-box > li {
    flex-direction: column;
}

#GcCookieBox ul.consent-elements-box > li .consent-element-name {
    width: 40%;
    flex-basis: 40%;
    font-weight: 600;
    display: inline-block;
    // margin-bottom: 5px; //check
}

#GcCookieBox ul.consent-elements-box > li .consent-element-value {
    width: 60%;
    flex-basis: 60%;
    display: inline-block;
    margin-bottom: 5px;
}
#GcCookieBox ul.consent-elements-box > li .consent-element-name,
#GcCookieBox ul.consent-elements-box > li .consent-element-value {
    width: 100%;
}

#GcCookieBoxAlter {
    position: fixed;
    left: 0;
    top: 50%;
    background: #fff;
    border: 1px solid #00000029;
    border-left: none;
    border-radius: 0 5px 5px 0;
    padding: 0.5rem;
    box-shadow: 0px 0px 5px #00000026;
    z-index: 1000;
}

#GcCookieBoxAlter a {
    color: #7a7a7a;
    font-size: 0.8rem;
}

#GcCookieBoxAlter a i {
    font-size: 1rem;
}

@keyframes bounceInDown {
    0% {
       opacity: 0;
       transform: translateY(-2000px);
    }
    60% {
       opacity: 1;
       transform: translateY(30px);
    }
    80% {
       transform: translateY(-10px);
    }
    100% {
       transform: translateY(0);
    }
}

.bounceInDown {
    animation-name: bounceInDown;
}

@keyframes rotateOutDownRight {
    from {
        transform-origin: right bottom;
        opacity: 1;
    }

    to {
        transform-origin: right bottom;
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0;
    }
}

.rotateOutDownRight {
    animation-name: rotateOutDownRight;
}

@keyframes transformZero {
    from {
        transform-origin: center;
        opacity: 1;
    }

    to {
        transform-origin: center;
        transform: rotate3d(1, 0, 0, -90deg);
        opacity: 0;
        height: 0;
    }
}

.transformZero {
    animation-name: transformZero;
}

#GcCookieBox.top_full_width #cookie-box-outer {
    align-items: flex-start;
}

#GcCookieBox.top_full_width .wrapper {
    max-width: 100%;
    margin: 0px;
}

#GcCookieBox.bottom_full_with #cookie-box-outer {
    align-items: flex-end;
}

#GcCookieBox.bottom_full_with .wrapper {
    max-width: 100%;
    margin: 0px 0px 0px 0px;
    border-radius: 0px;
}

#GcCookieBox.bottom_full_with .wrapper .cookie-box-simple {
    flex-wrap: wrap;
    display: flex;
    margin: 0 20px;
}

#GcCookieBox.bottom_full_with .cookie-header {
    flex-basis: 100%;
}

#GcCookieBox.bottom_full_with .cookie-settings,
#GcCookieBox.bottom_full_with .cookie-buttons {
    flex-basis: 50%;
}

#GcCookieBox.bottom_right #cookie-box-outer {
    align-items: flex-end;
    justify-content: flex-end;
}

#GcCookieBox.bottom_right .wrapper {
    margin: 0px 20px 20px 0px;
}

#GcCookieBox.bottom_left #cookie-box-outer {
    align-items: flex-end;
    justify-content: flex-start;
}

#GcCookieBox.bottom_left .wrapper {
    margin: 0px 0px 20px 20px;
}

#GcCookieBox.top_left #cookie-box-outer {
    align-items: flex-start;
    justify-content: flex-start;
}

#GcCookieBox.top_left .wrapper {
    margin: 0px 0px 20px 0px;
}

#GcCookieBox.top_right #cookie-box-outer {
    align-items: flex-start;
    justify-content: flex-end;
}

#GcCookieBox.top_right .wrapper {
    margin: 0px 0px 0px 20px;
}

#pwa-consent-layer-description {
    p {
        font-size: 11px;
        line-height: 1rem;
    }
}

/*******************************/
#GcCookieBox .compact-view {

    .cookie-heading {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 15px;
    }

    #pwa-consent-layer-description {
        margin-bottom: 4px;
        p {
            &:last-child {
                margin-bottom: 5px;
            }
        }
    }

    .legal {
        a {
            text-decoration: underline;
            color: #000;
            font-weight: bold;
        }
    }

    .consent-item .consent-header {
        padding: 10px 3px 10px 1px;
        &:hover {
            background: rgb(232, 234, 237);
        }
        .consent-heading > span {
            font-size: 15px;
            color: #000;
        }
    }

    .consent-switch {
        padding-right: 10px;
        cursor: pointer;
    }

    .consent-element-header span {
        font-weight: bold;
        font-size: 13px;
        color: #000;
    }
    .consent-content {
        margin-bottom: 20px;
    }
}
/*******************************/

@media (min-width: 992px) {
    #GcCookieBox .cookie-logo-container {
        display: block;
    }
}

@media (max-width: 768px) {
    #GcCookieBox .compact-view .accept-button-row {
        flex-wrap: wrap;
    }
    #GcCookieBox .compact-view .accept-button-row>* {
        flex-basis: 100%;
        margin: 0px 0px 10px 0px !important;
    }
}
